import { ref } from "vue";
import { AxiosError } from "axios";
import RouterService from "@/shared/services/router_service";
import { useAlertStore } from "@/shared/store/alert_store";
import LoginRequest from "../dtos/login_request";
import { authenticationRepository } from "@/shared/repositories/authentication_repository";
import { useLoginStore } from "@/modules/login/store/login_store";
import { usuarioRepository } from "@/shared/repositories/usuario_repository";
import { useSidebarRailStore } from "@/shared/store/sidebar_rail";
import { useSystemPermissionStore } from "@/shared/store/system_permission_store";

export default class LoginService {
  loadingLogin = ref<boolean>(false);
  loginStore = useLoginStore();
  alertStore = useAlertStore();
  sidebarRailStore = useSidebarRailStore();
  storeSystemPermission = useSystemPermissionStore();

  async submitLogin(loginRequest: LoginRequest): Promise<void> {
    this.loadingLogin.value = true;
    try {
      const response = await authenticationRepository.signIn(loginRequest);
      await this.setAccessToken(response.accessToken);
      await this.setRefreshToken(response.refreshToken);
      await this.setRefreshTokenExpiration(response.refreshTokenExpiration);
      this.loginStore.usuarioLogado = await usuarioRepository.whoami();
      this.loginStore.setDrawer(true);
      await this.performRequestPermissionForApplication();
      RouterService.redirectTo("/dashboard");
    } catch (e) {
      const axiosError = e as AxiosError<{ message: string }>;
      const convertedMessage = this.convertMessageToUTCMinus3(
        axiosError?.response?.data?.message
      );
      this.alertStore.showDialogAlert(convertedMessage);
    } finally {
      this.loadingLogin.value = false;
    }
  }

  async setAccessToken(accessToken: string): Promise<void> {
    sessionStorage.setItem("access_token", accessToken);
  }

  async setRefreshToken(refreshToken: string): Promise<void> {
    sessionStorage.setItem("refresh_token", refreshToken);
  }

  async setRefreshTokenExpiration(
    refreshTokenExpiration: string
  ): Promise<void> {
    sessionStorage.setItem("refresh_token_expiration", refreshTokenExpiration);
  }

  async setPermissoes(permissoes: string): Promise<void> {
    sessionStorage.setItem("permissions", permissoes);
  }

  public async performRequestPermissionForApplication() {
    try {
      this.storeSystemPermission.permissions =
        await authenticationRepository.permissionList();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        const axiosError = error.response.data.errors;
        for (const field in axiosError) {
          if (Object.prototype.hasOwnProperty.call(axiosError, field)) {
            const fieldValue = axiosError[field];
            useAlertStore().showDialogAlert(fieldValue);
          }
        }
      } else {
        useAlertStore().showDialogAlert(error as string);
      }
    }
  }

  clearCache(): void {
    sessionStorage.removeItem("drawer");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("refresh_token_expiration");
    sessionStorage.removeItem("permissions");
    this.loginStore.setDrawer(false);
  }

  convertMessageToUTCMinus3(message: string | undefined): string | undefined {
    if (!message) {
      return undefined;
    }

    const dateRegex = /(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2})/;
    const match = message.match(dateRegex);

    if (match) {
      const dateString = match[1];
      const originalDate = new Date(dateString);

      const dateMinus3 = new Date(
        originalDate.setUTCHours(originalDate.getUTCHours() - 3)
      );

      const formattedDate = `${dateMinus3.getFullYear()}/${(
        dateMinus3.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${dateMinus3
        .getDate()
        .toString()
        .padStart(2, "0")} ${dateMinus3
        .getHours()
        .toString()
        .padStart(2, "0")}:${dateMinus3
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${dateMinus3
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      const updatedMessage = message.replace(dateString, formattedDate);

      return updatedMessage;
    } else {
      return message;
    }
  }

  async logout(): Promise<void> {
    this.loginStore.logout();
    this.clearCache();
    this.sidebarRailStore.resetState();
    RouterService.redirectTo("/");
  }
}
