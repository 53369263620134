import {
  ChangePasswordRepository,
  changePasswordRepository,
} from "@/shared/repositories/change_password_repository";
import Password from "@/shared/components/dtos/password";
import { useAlertStore } from "@/shared/store/alert_store";
import { useChangePasswordStore } from "../store";

export class ChangePasswordUpdateService {
  private readonly changePasswordRepository: ChangePasswordRepository;

  constructor(changePasswordRepository: ChangePasswordRepository) {
    this.changePasswordRepository = changePasswordRepository;
  }

  public async update(request: Password): Promise<void> {
    const storeChangePasswordStore = useChangePasswordStore();
    storeChangePasswordStore.changePasswordLoading = true;
    try {
      await this.changePasswordRepository.update(request);
      useAlertStore().showSuccessMessage();
      storeChangePasswordStore.changePasswordDialog = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      storeChangePasswordStore.message = error.response.data;
      storeChangePasswordStore.alertMessageError = true;
    } finally {
      storeChangePasswordStore.changePasswordLoading = false;
    }
  }
}

export const changePasswordUpdateService: ChangePasswordUpdateService =
  new ChangePasswordUpdateService(changePasswordRepository);
