import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "@/modules/login/ui/views/LoginView.vue";
import { useLoginStore } from "@/modules/login/store/login_store";
import { PermissionsEnum } from "@/shared/helpers/permissions_enum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/recuperar-senha",
    name: "recuperarSenha",
    component: () => import("@/modules/login/ui/views/ForgotPasswordView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/confirmacao-usuario",
    name: "novoUsuario",
    component: () => import("@/modules/login/ui/views/NewUserView.vue"),
    meta: {
      requiresAuth: false,
      subtitle: "",
      permission: 0,
    },
  },
  {
    path: "/nova-senha",
    name: "novaSenha",
    component: () => import("@/modules/login/ui/views/NewPasswordView.vue"),

    meta: {
      requiresAuth: false,
      subtitle: "",
      permission: 0,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/modules/dashboard/ui/views/DashboardView.vue"),
    meta: {
      requiresAuth: false,
      subtitle: "",
      permission: 0,
    },
  },
  {
    path: "/financiadoras",
    name: "Financiadoras",
    component: () =>
      import("@/modules/financiadoras/ui/views/FinanciadorasView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.FinanciadoraListar,
    },
  },
  {
    path: "/financiadora-colaboradores",
    name: "Financiadora Colaboradores",
    component: () =>
      import(
        "@/modules/financiadora_colaboradores/ui/FinanciadoraColaboradorIndex.vue"
      ),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.FinanciadoraColaboradorListar,
    },
  },
  {
    path: "/distribuidoras",
    name: "Distribuidoras",
    component: () => import("@/modules/distribuidora/ui/DistribuidoraView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.DistribuidoraListar,
    },
  },
  {
    path: "/Integradores",
    name: "Integradores",
    component: () => import("@/modules/integradores/ui/IntegradorView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.IntegradorListar,
    },
  },
  {
    path: "/integrador-colaboradores",
    name: "Integrador Colaboradores",
    component: () =>
      import(
        "@/modules/integrador_colaboradores/ui/views/IntegradorColaboradoresView.vue"
      ),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.IntegradorColaboradorListar,
    },
  },
  {
    path: "/perfis",
    name: "Perfis",
    component: () => import("@/modules/perfil/ui/PerfisView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.PerfilListar,
    },
  },
  {
    path: "/usuarios",
    name: "Usuários",
    component: () => import("@/modules/usuarios/ui/UsuarioIndex.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.UsuarioListar,
    },
  },
  {
    path: "/dispositivos",
    name: "Dispositivos",
    component: () => import("@/modules/dispositivos/ui/DispositivosIndex.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.DispositivoListar,
    },
  },
  {
    path: "/marcas-dispositivo",
    name: "Marcas Dispositivo",
    component: () =>
      import("@/modules/marcas_dispositivo/ui/views/MarcasDispositivoView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.MarcaDispositivoListar,
    },
  },
  {
    path: "/modelos-dispositivo",
    name: "Modelos Dispositivo",
    component: () =>
      import(
        "@/modules/modelos_dispositivo/ui/views/ModelosDispositivoView.vue"
      ),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.ModeloDispositivoListar,
    },
  },
  {
    path: "/tipos-dispositivo",
    name: "Tipos Dispositivo",
    component: () =>
      import("@/modules/tipos_dispositivo/ui/TiposDispositivo.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.TipoDispositivoListar,
    },
  },
  {
    name: "",
    path: "/nao-encontrado",
    component: () => import("@/modules/dashboard/ui/views/DashboardView.vue"),
  },
  {
    path: "/clientes",
    name: "Gerenciamento de Clientes",
    component: () => import("@/modules/clientes/ui/ClienteView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "Cadastre, edite e ative ou desative os seus clientes.",
      permission: PermissionsEnum.ClienteListar,
    },
  },
  {
    path: "/financiamento",
    name: "Processo de Financiamentos",
    component: () => import("@/modules/financiamento/ui/FinanciamentoView.vue"),
    meta: {
      requiresAuth: true,
      subtitle:
        "Acompanhe as etapas do processo, desde o cadastro do financiamento até a ativação dos novos dispositivos.",
      permission: PermissionsEnum.FinanciamentoListar,
    },
  },
  {
    path: "/monitoramento/clientes",
    name: "Monitoramento",
    component: () => import("@/modules/monitoramento/ui/MonitoramentoView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "Gerencie todos os clientes e seus dispositivos.",
      permission: PermissionsEnum.FinanciamentoListar,
    },
  },
  {
    path: "/agendamentos-instalacao",
    name: "Agendamentos",
    component: () => import("@/modules/agendamento/AgendamentosView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "Gerencie todos os agendamentos das instalações.",
      permission: PermissionsEnum.AgendamentoListar,
    },
  },
  {
    path: "/monitoramento/intervencoes",
    name: "Intervenções",
    component: () => import("@/modules/intervencao/ui/IntervencaoView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "Gerencie e analise todas as intervenções.",
      permission: PermissionsEnum.IntervencaoListar,
    },
  },
  {
    path: "/cliente/home",
    name: "",
    component: () => import("@/modules/cliente_final/ui/ClienteFinalView.vue"),
    meta: {
      requiresAuth: true,
      subtitle: "",
      permission: PermissionsEnum.FinanciamentoListarPorCliente,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuth = !!sessionStorage.getItem("access_token");
  const storeLogin = useLoginStore();
  const myPermissions = storeLogin.usuarioLogado.permissoes;

  if (
    to.name !== "Login" &&
    !isAuth &&
    to.name !== "recuperarSenha" &&
    to.name !== "novoUsuario" &&
    to.name !== "novaSenha"
  )
    next({ name: "Login" });
  else {
    if (to.path === "/" && isAuth) {
      next({
        path: "/dashboard",
      });
    } else {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (myPermissions.includes(to.meta.permission as number)) {
          next();
        } else {
          next({
            path: "/nao-encontrado",
          });
        }
      } else {
        next();
      }
    }
  }
  if (to.name == "recuperarSenha" && !isAuth) next({ name: "recuperarSenha" });
  if (to.name == "novoUsuario" && !isAuth) next({ name: "novoUsuario" });
  if (to.name == "novaSenha" && !isAuth) next({ name: "novaSenha" });
});

export default router;
