import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { PermissionDto } from "@/modules/perfil/dtos/permission_dto";

export const useSystemPermissionStore = defineStore(
  "system-permission-store",
  () => {
    const permissions = ref<PermissionDto[]>([]);

    const PermissionsEnum = computed(() =>
      permissions.value.reduce((enumValues, tipo, index) => {
        enumValues[tipo.nome] = tipo.permissaoId;
        return enumValues;
      }, {} as Record<string, number>)
    );

    return {
      permissions,
      PermissionsEnum,
    };
  },
  {
    persist: true,
  }
);
