import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useSidebarRailStore = defineStore(
  "sidebarRail",
  () => {
    const rail = ref<boolean>(false);
    const selectedMenuItem = ref("");

    function toggleRail() {
      rail.value = !rail.value;
    }

    function resetState() {
      rail.value = false;
      selectedMenuItem.value = "/dashboard";
    }

    const getRail = computed(() => rail.value);
    const getSelectedItem = computed(() => selectedMenuItem.value);

    return {
      rail,
      toggleRail,
      getRail,
      selectedMenuItem,
      getSelectedItem,
      resetState,
    };
  },
  {
    persist: true,
  }
);
