export enum PermissionsEnum {
  TipoDispositivoCadastrar = 1,
  TipoDispositivoAtualizar = 2,
  TipoDispositivoListar = 3,
  MarcaDispositivoCadastrar = 4,
  MarcaDispositivoAtualizar = 5,
  MarcaDispositivoListar = 6,
  ModeloDispositivoCadastrar = 7,
  ModeloDispositivoAtualizar = 8,
  ModeloDispositivoListar = 9,
  DispositivoCadastrar = 10,
  DispositivoAtualizar = 11,
  DispositivoListar = 12,
  UsuarioCadastrar = 13,
  UsuarioAtualizar = 14,
  UsuarioListar = 15,
  IntegradorColaboradorCadastrar = 16,
  IntegradorColaboradorAtualizar = 17,
  IntegradorColaboradorListar = 18,
  PerfilCadastrar = 19,
  PerfilAtualizar = 20,
  PerfilListar = 21,
  IntegradorCadastrar = 22,
  IntegradorAtualizar = 23,
  IntegradorListar = 24,
  FinanciadoraCadastrar = 25,
  FinanciadoraAtualizar = 26,
  FinanciadoraListar = 27,
  FinanciadoraColaboradorCadastrar = 28,
  FinanciadoraColaboradorAtualizar = 29,
  FinanciadoraColaboradorListar = 30,
  DistribuidoraCadastrar = 31,
  DistribuidoraAtualizar = 32,
  DistribuidoraListar = 33,
  ClienteCadastrar = 34,
  ClienteAtualizar = 35,
  ClienteListar = 36,
  FinanciamentoListar = 37,
  FinanciamentoCadastrar = 38,
  FinanciamentoEditar = 39,
  ValidacaoSmsCadastrar = 44,
  ValidacaoSmsConsultar = 45,
  InstalacaoCadastrar = 46,
  AgendamentoCadastrar = 47,
  AgendamentoEditar = 48,
  AgendamentoListar = 49,
  SolicitarIntervencao = 50,
  IntervencaoListar = 51,
  DecidirIntervencao = 52,
  CancelarIntervencao = 53,
  FinanciamentoListarPorCliente = 54,
}
