const filters = {
  dateDmyHms(input: string) {
    const dateObj = new Date(input);
    return dateObj.toLocaleString("pt-BR").substring(0, 20).replace(",", "");
  },

  dateDmy(input: string) {
    const dateObj = new Date(input);
    return dateObj.toLocaleString("pt-BR").substring(0, 10);
  },
};
export default filters;
