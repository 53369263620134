import BaseRepository from "./base_repository";
import { UsuarioDto } from "@/modules/usuarios/dtos/usuario_dto";
import { UsuarioListingRequestDto } from "@/modules/usuarios/dtos/usuario_listing_request_dto";
import { UsuarioRequestDto } from "@/modules/usuarios/dtos/usuario_request_dto";
import NewUserRequestDto from "@/modules/login/dtos/new_user_request_dto";
import NewUserDto from "@/modules/login/dtos/new_user_dto";
import { PerfilDto } from "@/modules/usuarios/dtos/perfil_dto";
import { PaginatedList } from "@/shared/models/paginated_list";
import { Pagination } from "@/shared/models/pagination";
import { UsuarioLogado } from "@/shared/models/usuario_logado";

export class UsuarioRepository extends BaseRepository {
  async findAll(request: UsuarioListingRequestDto): Promise<UsuarioDto[]> {
    return await this.get<UsuarioDto[]>("usuarios", {
      ...request,
    });
  }
  async findAllPaginatedList(
    pagination: Pagination,
    request: UsuarioListingRequestDto
  ): Promise<PaginatedList<UsuarioDto>> {
    return await this.get<PaginatedList<UsuarioDto>>("usuarios", {
      ...request,
      ...pagination,
    });
  }

  async register(request: UsuarioRequestDto): Promise<UsuarioDto> {
    return await this.post<UsuarioDto, UsuarioRequestDto>("usuarios", request);
  }

  async update(uuid: string, request: UsuarioRequestDto): Promise<UsuarioDto> {
    return await this.put<UsuarioDto, UsuarioRequestDto>(
      `usuarios/${uuid}`,
      request
    );
  }

  async getPerfilList(): Promise<PerfilDto[]> {
    return await this.get<PerfilDto[]>("perfis?ativo=1", {});
  }
  async submitNewPassword(
    uuid: string,
    request: NewUserRequestDto
  ): Promise<NewUserDto> {
    return await this.patch<NewUserDto, NewUserRequestDto>(
      `usuarios/${uuid}/confirm`,
      request
    );
  }

  async whoami(): Promise<UsuarioLogado> {
    return await this.get<UsuarioLogado>(`usuarios/whoami`);
  }
}
export const usuarioRepository = new UsuarioRepository();
