import env from "@/env";
import axios from "axios";

const baseURL = env.baseUrl;
const axiosInstance = axios.create({ baseURL });
axiosInstance.defaults.timeout = 20_000;
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("access_token")}`;
export default axiosInstance;
