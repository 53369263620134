import { createApp } from "vue";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import "vuetify/styles";
import { createVuetify, ThemeDefinition } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";

import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";

import App from "./App.vue";
import router from "./shared/router";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import filters from "./shared/helpers/filters";

const corteAutomaticoLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#EEEEEE",
    surface: "#FFFFFF",
    primary: "#FF8B3D",
    "primary-darken-1": "#3700B3",
    secondary: "#9575CD",
    "secondary-accent": "#6200EA",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    "orange-dark": "#E52A0B",
  },
};

const vuetify = createVuetify({
  theme: {
    defaultTheme: "corteAutomaticoLightTheme",
    themes: { corteAutomaticoLightTheme },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  components,
  directives,
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.config.globalProperties.$filters = filters;
app
  .use(router)
  .use(pinia)
  .use(vuetify)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");
