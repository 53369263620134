import axiosInstance from "./axios_instance";
import { useAlertStore } from "@/shared/store/alert_store";
import { AxiosError, AxiosResponse } from "axios";

export default class BaseRepository {
  // private accessToken: string | null = sessionStorage.getItem("access_token");
  // private refreshToken: string | null = sessionStorage.getItem("refresh_token");
  // private accessTokenExpirationTime: string | null = sessionStorage.getItem(
  //   "refresh_token_expiration"
  // );

  protected async get<T>(path: string, params?: object): Promise<T> {
    await this.handlerRefreshAccessToken();

    try {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${sessionStorage.getItem("access_token")}`;
      const url = axiosInstance.defaults.baseURL + "/" + path;
      let response;
      const hasSearchParams = params && "search" in params;
      if (params) {
        response = await axiosInstance.get<T>(url, { params });
      } else {
        response = await axiosInstance.get<T>(url);
      }
      hasSearchParams
        ? (useAlertStore().hasSearchParams = true)
        : useAlertStore().hasSearchParams;
      return response.data;
    } catch (error) {
      console.error({ method: "GET", path, params, error });
      const axiosError = error as AxiosError<{ response: AxiosResponse }>;
      if (axiosError.response?.status === 401) {
        this.redirectUserWithoutToken();
      }
      throw error;
    }
  }

  protected async post<T, K>(path: string, requestBody: K): Promise<T> {
    await this.handlerRefreshAccessToken();

    try {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${sessionStorage.getItem("access_token")}`;
      const url = axiosInstance.defaults.baseURL + "/" + path;
      const response = await axiosInstance.post<T>(url, requestBody);
      return response.data;
    } catch (error) {
      console.error({ method: "POST", path, requestBody, error });
      const axiosError = error as AxiosError<{ response: AxiosResponse }>;
      if (axiosError.response?.status === 401) {
        this.redirectUserWithoutToken();
      }
      throw error;
    }
  }

  protected async put<T, K>(path: string, requestBody: K): Promise<T> {
    await this.handlerRefreshAccessToken();

    try {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${sessionStorage.getItem("access_token")}`;
      const url = axiosInstance.defaults.baseURL + "/" + path;
      const response = await axiosInstance.put<T>(url, requestBody);
      return response.data;
    } catch (error) {
      console.error({ method: "PUT", path, requestBody, error });
      const axiosError = error as AxiosError<{ response: AxiosResponse }>;
      if (axiosError.response?.status === 401) {
        this.redirectUserWithoutToken();
      }
      throw error;
    }
  }

  protected async patch<T, K>(path: string, requestBody: K): Promise<T> {
    await this.handlerRefreshAccessToken();

    try {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${sessionStorage.getItem("access_token")}`;
      const url = axiosInstance.defaults.baseURL + "/" + path;
      const response = await axiosInstance.patch<T>(url, requestBody);
      return response.data;
    } catch (error) {
      console.error({ method: "PATCH", path, requestBody, error });
      const axiosError = error as AxiosError<{ response: AxiosResponse }>;
      if (axiosError.response?.status === 401) {
        this.redirectUserWithoutToken();
      }
      throw error;
    }
  }

  private async redirectUserWithoutToken() {
    sessionStorage.clear();
    localStorage.clear();
  }

  private async handlerRefreshAccessToken() {
    const actualDateTime = sessionStorage.getItem("refresh_token_expiration");
    if (!actualDateTime) return;
    const targetDate = new Date(actualDateTime);

    const givenDateTime = new Date();
    const expirationDateTime = new Date(targetDate.getTime() + 7 * 60000);

    if (givenDateTime === expirationDateTime) {
      await this.refreshAccessToken();
    }
  }

  private async refreshAccessToken() {
    try {
      const refreshToken = sessionStorage.getItem("refresh_token");
      if (!refreshToken) return;
      const url = `/authentication/refresh-token`;
      const body = { refreshToken: refreshToken };
      const response = await axiosInstance.post(url, body);

      const accessToken = response.data.accessToken;
      const newRefreshToken = response.data.refreshToken;
      const accessTokenExpirationTime = response.data.refreshTokenExpiration;

      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("refresh_token", newRefreshToken);
      sessionStorage.setItem(
        "refresh_token_expiration",
        accessTokenExpirationTime
      );
      return;
    } catch (error) {
      console.error({
        method: "refreshAccessToken",
        error: JSON.stringify(error),
      });
      throw error;
    }
  }
  protected async getPDF<ArrayBuffer>(
    path: string,
    params?: object
  ): Promise<ArrayBuffer> {
    await this.handlerRefreshAccessToken();

    try {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${sessionStorage.getItem("access_token")}`;
      const url = axiosInstance.defaults.baseURL + "/" + path;
      let response;
      const hasSearchParams = params && "search" in params;
      if (params) {
        response = await axiosInstance.get<ArrayBuffer>(url, { params });
      } else {
        response = await axiosInstance.get<ArrayBuffer>(url);
      }
      hasSearchParams
        ? (useAlertStore().hasSearchParams = true)
        : useAlertStore().hasSearchParams;
      return response.data;
    } catch (error) {
      console.error({ method: "GET", path, params, error });
      const axiosError = error as AxiosError<{ response: AxiosResponse }>;
      if (axiosError.response?.status === 401) {
        this.redirectUserWithoutToken();
      }
      throw error;
    }
  }
}
