import { defineStore } from "pinia";
import LoginResponse from "@/modules/login/dtos/login_response";
import { UsuarioLogado } from "@/shared/models/usuario_logado";
import { ref, computed } from "vue";

export const useLoginStore = defineStore(
  "login",
  () => {
    const authUser = ref<LoginResponse>({
      accessToken: "",
      refreshToken: "",
      expiresIn: "",
      refreshTokenExpiration: "",
      drawer: false,
    });

    const usuarioLogado = ref<UsuarioLogado>({
      email: "",
      login: "",
      cpf: "",
      nome: "",
      emailConfirmado: false,
      perfilId: 0,
      perfilUuid: "",
      perfilNome: "",
      ativo: false,
      integradorUuid: "",
      financiadoraUuid: "",
      permissoes: [],
      passwordMustToBeChanged: false,
      criadoEm: "",
      criadoPor: "",
      modificadoEm: "",
      modificadoPor: "",
      uuid: "",
    });

    const solicitacaoResetSenhaUuid = ref<string>("");

    const isAdmin = computed(() => {
      return usuarioLogado.value.perfilId === 1;
    });

    const isFinanciadora = computed(() => {
      return usuarioLogado.value.perfilId === 2;
    });

    const isIntegrador = computed(() => {
      return usuarioLogado.value.perfilId === 3;
    });

    function setAccessToken(accessToken: string) {
      sessionStorage.setItem("access_token", accessToken);
    }

    function setAuthUser(authenticationResponse: LoginResponse) {
      authUser.value = authenticationResponse;
    }

    function setRefreshToken(refreshToken: string) {
      sessionStorage.setItem("refresh_token", refreshToken);
    }

    function setRefreshTokenExpiration(refreshTokenExpiration: string) {
      sessionStorage.setItem(
        "refresh_token_expiration",
        refreshTokenExpiration
      );
    }
    function setDrawer(drawer: boolean): void {
      authUser.value.drawer = drawer;
      sessionStorage.setItem("drawer", drawer.toString());
    }
    function logout(): void {
      setDrawer(false);
      sessionStorage.clear();
      localStorage.clear();
    }

    return {
      authUser,
      usuarioLogado,
      solicitacaoResetSenhaUuid,
      isAdmin,
      isFinanciadora,
      isIntegrador,
      setAccessToken,
      setAuthUser,
      setRefreshToken,
      setRefreshTokenExpiration,
      setDrawer,
      logout,
    };
  },
  {
    persist: true,
  }
);
