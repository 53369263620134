import { defineStore } from "pinia";
import { ref } from "vue";
import { MessageRequestDto } from "@/modules/change_password/dtos/message_request_dto";
export const useChangePasswordStore = defineStore("change-password", () => {
  const changePasswordDialog = ref<boolean>(false);
  const changePasswordLoading = ref<boolean>(false);
  const message = ref<MessageRequestDto>({ message: "" });
  const alertMessageError = ref<boolean>(false);
  return {
    changePasswordDialog,
    changePasswordLoading,
    message,
    alertMessageError,
  };
});
