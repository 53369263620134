import { defineStore } from "pinia";
import { ref } from "vue";
import { Rules } from "../components/dtos/rules";

// export interface rulesStore

/* eslint-disable */

export const useRulesStore = defineStore("rulesStore", () => {
  const rules = ref<Rules>({
    required: (value: any) => !!value || "O campo é obrigatório",
    validEmail: (value: any) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
      "O e-mail deve ser válido",
  });

  return { rules };
});
