<script setup>
import { useRoute } from "vue-router";
import NavbarComponent from "@/shared/components/menu/NavbarComponent.vue";
import SidebarComponent from "@/shared/components/menu/SidebarComponent.vue";
import RouterViewComponent from "@/shared/components/menu/RouterViewComponent.vue";
import AlertComponent from "@/shared/components/alerts/AlertComponent.vue";

const route = useRoute();
</script>

<template>
  <v-app id="app">
    <NavbarComponent
      novaSenha
      v-if="
        !['Login'].includes(route.name) &&
        !['recuperarSenha'].includes(route.name) &&
        !['novoUsuario'].includes(route.name) &&
        !['novaSenha'].includes(route.name)
      "
    />
    <SidebarComponent
      v-if="
        !['Login'].includes(route.name) &&
        !['recuperarSenha'].includes(route.name) &&
        !['novoUsuario'].includes(route.name) &&
        !['novaSenha'].includes(route.name)
      "
    />
    <AlertComponent />
    <v-main>
      <RouterViewComponent
        v-if="
          !['Login'].includes(route.name) &&
          !['recuperarSenha'].includes(route.name) &&
          !['novoUsuario'].includes(route.name) &&
          !['novaSenha'].includes(route.name)
        "
        :routeTitle="route.name"
        :routeSubtitle="route.meta.subtitle"
      />
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
