import { FormRules } from "./interfaces";

export const formRules: FormRules = {
  required: function (value: string): boolean | string {
    return !!value || "O campo é obrigatório";
  },
  validEmail: function (value: string): boolean | string {
    const isValidEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      value
    );
    return isValidEmail || "O e-mail deve ser válido";
  },
  maxLength: function (
    maxLength: number,
    errorMessage?: string
  ): (value: string) => boolean | string {
    return function (str: string) {
      if (!str) {
        return true;
      }
      const isValid = str.length <= maxLength;
      return (
        isValid ||
        (errorMessage ?? "Insira no máximo $maxLength caracteres").replace(
          "$maxLength",
          `${maxLength}`
        )
      );
    };
  },
  minLength: function (
    minLength: number,
    errorMessage?: string
  ): (value: string) => boolean | string {
    return function (str: string) {
      if (!str) {
        return true;
      }
      const isValid = str.length >= minLength;
      return (
        isValid ||
        (errorMessage ?? "Insira no mínimo $minLength caracteres").replace(
          "$minLength",
          `${minLength}`
        )
      );
    };
  },
  validCPF: function (value: string): boolean | string {
    return (!!value && /^\d+$/.test(value)) || "CPF deve conter apenas números";
  },
  validPhoneNumber: function (value: string): boolean | string {
    return (
      (!!value && /^\(\d{2}\) \d{5}-\d{4}$/.test(value)) ||
      "Insira um número válido"
    );
  },
  validCNPJ: function (value: string): boolean | string {
    if (!value) return "Campo CNPJ é obrigatório.";
    const numericCNPJ = value.replace(/\D+/g, "");
    if (!/^\d{14}$/.test(numericCNPJ))
      return "CNPJ inválido. Deve conter 14 dígitos.";
    return true;
  },
  matches: function (
    value: string,
    errorMessage?: string
  ): (value: string) => boolean | string {
    return function (str: string) {
      if (!str) {
        return true;
      }
      const isValid = str === value;
      return isValid || (errorMessage ?? "As senhas não correspondem");
    };
  },
  validTelefone: function (value: string): boolean | string {
    if (!value) return "Campo telefone é obrigatório.";
    const numericTelefone = value.replace(/\D+/g, "");
    if (!/^\d{11}$/.test(numericTelefone))
      return "Telefone inválido. Deve conter 11 dígitos.";
    return true;
  },
  validCEP: function (
    errorMessage?: string
  ): (value: string) => boolean | string {
    return function (str: string) {
      if (!str) {
        return true;
      }
      const validCharacters = str.replace(/-/g, "");
      const isValid = validCharacters.length <= 8;
      return isValid || (errorMessage ?? "Insira um CEP válido");
    };
  },
  validTotalValue: function (
    errorMessage?: string
  ): (value: string) => boolean | string {
    return function (str: string) {
      if (!str) {
        return true;
      }
      const validCharacters = str.replace(/[,.]/g, "");
      const isValid = validCharacters.length <= 12;
      return isValid || (errorMessage ?? "Insira no máximo 12 caracteres");
    };
  },
  validDecimal: function (value: string): boolean | string {
    if (!/^\d+(\.\d{1,2})?$/.test(value))
      return "Valor inválido. Insira no máximo 2 casas decimais.";

    return true;
  },
};
