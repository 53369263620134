import LoginRequest from "@/modules/login/dtos/login_request";
import BaseRepository from "./base_repository";
import LoginResponse from "@/modules/login/dtos/login_response";
import SolicitacaoResetSenhaDto from "@/modules/login/dtos/solicitacao_reset_senha_dto";
import ForgotPasswordRequestDto from "@/modules/login/dtos/forgot_password_request_dto";
import ForgotPasswordNewPasswordRequestDto from "@/modules/login/dtos/forgot_password_new_password_request";
import { PermissionDto } from "@/modules/perfil/dtos/permission_dto";

import NewUserDto from "@/modules/login/dtos/new_user_dto";
export class AuthenticationRepository extends BaseRepository {
  async signIn(request: LoginRequest): Promise<LoginResponse> {
    return await this.post<LoginResponse, LoginRequest>(
      "authentication/sign-in",
      request
    );
  }

  async permissionList(): Promise<PermissionDto[]> {
    const list = await this.get<PermissionDto[]>("permissoes");
    return list;
  }

  async confirmForgotPasswordToken(
    token: string
  ): Promise<SolicitacaoResetSenhaDto> {
    return await this.get<SolicitacaoResetSenhaDto>(
      `authentication/forgot-password/${token}/validate`
    );
  }

  async defineNewPassword(
    solicitacaoResetSenhaUuid: string,
    request: ForgotPasswordNewPasswordRequestDto
  ) {
    return await this.patch<never, ForgotPasswordNewPasswordRequestDto>(
      `authentication/forgot-password/${solicitacaoResetSenhaUuid}`,
      request
    );
  }

  async submitForgotPassword(request: ForgotPasswordRequestDto): Promise<void> {
    return await this.post<never, ForgotPasswordRequestDto>(
      "authentication/forgot-password",
      request
    );
  }
  async confirmPasswordToken(token: string): Promise<NewUserDto> {
    return await this.get<NewUserDto>(`authentication/validate-user/${token}`);
  }
}

export const authenticationRepository = new AuthenticationRepository();
